// dataUpdater.js
import { executeQuery } from '../integrations/db_connection.js';
import { tableData } from './tableMappings.js';
import { companyConfig } from './companyConfig.js';

export const updateDataContent = async (companyName) => {
    if (!tableData[companyName]) {
        tableData[companyName] = {};
    }

    const tables = companyConfig[companyName]?.tables;

    if (!tables) {
        console.error(`No configuration found for company: ${companyName}`);
        return;
    }

    try {
        for (const [tableName, query] of Object.entries(tables)) {
            const result = await executeQuery(query);
            if (Array.isArray(result) && result.length > 0) {
                console.log(`${companyName} ${tableName} Query result:`, result);
                tableData[companyName][tableName] = result;
            } else {
                console.warn(`${companyName} ${tableName} query returned no results or invalid data`);
                tableData[companyName][tableName] = [];
            }
        }
    } catch (error) {
        console.error(`Error updating ${companyName} data:`, error);
        Object.keys(tables).forEach(tableName => {
            tableData[companyName][tableName] = [];
        });
    }
};
export const tableData = {};

export const columnMap = {
    id: 'ID',
    location: 'Location',
    status: 'Status',
    power: 'Power Output',
    type: 'Charging Type',
    id_tag: 'RFID Tag',
    total_distance: 'Total kWh Charged',
    week1_distance: 'Week 1 - kWh Charged',
    week2_distance: 'Week 2 - kWh Charged',
    week3_distance: 'Week 3 - kWh Charged',
    week4_distance: 'Week 4 - kWh Charged',
    week5_distance: 'Week 5 - kWh Charged',
    jan_distance: 'January - kWh Charged',
    feb_distance: 'February - kWh Charged',
    mar_distance: 'March - kWh Charged',
    apr_distance: 'April - kWh Charged',
    may_distance: 'May - kWh Charged',
    jun_distance: 'June - kWh Charged',
    jul_distance: 'July - kWh Charged',
    aug_distance: 'August - kWh Charged',
    sep_distance: 'September - kWh Charged',
    oct_distance: 'October - kWh Charged',
    nov_distance: 'November - kWh Charged',
    dec_distance: 'December - kWh Charged',
    weekly_total: 'Weekly Total kWh Charged',
    monthly_total: 'Monthly Total kWh Charged',
    yearly_total: 'Yearly Total kWh Charged',
    cumulative_total: 'Cumulative Total kWh Charged',
    year_number: 'Year',
    serial_number: 'Serial Number',

};

export const getColumns = (data) => {
    if (!data || data.length === 0) return [];
    return Object.keys(data[0]).map(key => ({
      Header: columnMap[key] || key,
      accessor: key
    }));
};

export const tableNameMapping = {
  rfid_tags_this_week: "RFID Tags - This Week",
  rfid_tags_this_month: "RFID Tags - This Month",
  rfid_tags_week: "RFID Tags - Week",
  rfid_tags_month: "RFID Tags - Month",
  rfid_tags_year: "RFID Tags - Year",
  rfid_tags_this_year: "RFID Tags - This Year",
  rfid_tag_last_month: "RFID Tags - Last Month",
  charged_oversight_weekly: "Charged Oversight - Weekly",
  charged_oversight_monthly: "Charged Oversight - Monthly",
};
export const companyConfig = {
    brommaStal: {
      tables: {
        rfid_tags_month: 'queryRfidExactMonth',
        rfid_tags_week: 'queryRfidExactWeek',
        rfid_tags_year: 'queryRfidExactYear',
        rfid_tags_this_week: 'queryRfidThisWeek',
        rfid_tags_this_month: 'queryRfidThisMonth',
        rfid_tags_this_year: 'queryRfidThisYear',
        rfid_tags_last_month: 'queryRfidLastMonthEachWeek',
      //  rfid_tags_last_year: 'queryRfidLastYearEachMonth'
      }
    },
    chargeIt: {
      tables: {
        rfid_tags_month: 'queryRfidExactMonth',
        rfid_tags_week: 'queryRfidExactWeek',
        rfid_tags_year: 'queryRfidExactYear',
        rfid_tags_this_week: 'queryRfidThisWeek',
        rfid_tags_this_month: 'queryRfidThisMonth',
        rfid_tags_this_year: 'queryRfidThisYear',
        rfid_tags_last_month: 'queryRfidLastMonthEachWeek',
      //  rfid_tags_last_year: 'queryRfidLastYearEachMonth'
      }
    },
    powerMate: {
      tables: {
        charged_oversight_weekly: 'queryChargedOversightWeekly',
        charged_oversight_monthly: 'queryChargedOversightMonthly',
        ghost: 'queryGhost',
      }
    },
    parkIt: {
      tables: {
        charged_oversight_weekly: 'queryChargedOversightWeekly',
        charged_oversight_monthly: 'queryChargedOversightMonthly',
        rfid_tags_this_month: 'queryRfidThisMonth',
      }
    }
};
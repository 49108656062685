import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, getUserDataset } from './integrations/firebase';
import { signOut } from 'firebase/auth';
import ChargePointTable from './components/chargePointTable/ChargePointTable';
import AuthPage from './components/authPage/AuthPage';
import ChangePassword from './components/ChangePassword';
import Header from './components/Header';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #6AAE47;
    margin: 0;
    padding: 0;
  }
`;

function App() {
  const [user, loading] = useAuthState(auth);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const handleLogout = () => {
    signOut(auth);
  };

  const renderContent = () => {
    if (loading) {
      return <div>Loading...</div>;
    }
    if (!user) {
      return <AuthPage />;
    }
    if (showChangePassword) {
      return <ChangePassword onClose={() => setShowChangePassword(false)} />;
    }
    const dataset = getUserDataset(user);
    return (
      <>
        {dataset ? (
          <ChargePointTable dataset={dataset} />
        ) : (
          <p>You do not have access to view the charge point tables.</p>
        )}
      </>
    );
  };

  return (
    <>
      <GlobalStyle />
      <div className="App">
        <Header 
          user={user}
          onLogout={handleLogout}
          onChangePassword={() => setShowChangePassword(true)}
        />
        {renderContent()}
      </div>
    </>
  );
}

export default App;
import React from 'react';
import styled from 'styled-components';

const TableWrapper = styled.div`
  margin-bottom: 30px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  overflow: hidden;
`;

const TableHeader = styled.th`
  background-color: #34495e;
  color: white;
  font-weight: 600;
  text-align: left;
  padding: 16px 20px;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
`;

const TableCell = styled.td`
  padding: 16px 20px;
  border-bottom: 1px solid #ecf0f1;
  color: #2c3e50;
  font-size: 15px;
`;

const TableRow = styled.tr`
  transition: background-color 0.3s ease;

  &:last-child ${TableCell} {
    border-bottom: none;
  }

  &:nth-child(even) {
    background-color: #f0f0f0; // Light gray color for even rows
  }

  &:nth-child(odd) {
    background-color: #ffffff; // White color for odd rows
  }

  &:hover {
    background-color: #e6e6e6; // Slightly darker gray on hover
  }
`;

const Button = styled.button`
  margin-right: 12px;
  margin-bottom: 12px;
  padding: 10px 18px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  }
`;

const Table = ({ tableInstance }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <TableWrapper>
      {/*<Button>Add New</Button>
      <Button>Export</Button>*/}
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableHeader {...column.getHeaderProps()}>
                  {column.render('Header')}
                </TableHeader>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <TableCell {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
        </tbody>
      </StyledTable>
    </TableWrapper>
  );
};

export default Table;
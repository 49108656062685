import React from 'react';

const AuthForm = ({ isLogin, email, password, onEmailChange, onPasswordChange, onSubmit }) => (
  <form onSubmit={onSubmit}>
    <div className="form-group">
      <label>Email:</label>
      <input 
        type="email" 
        value={email} 
        onChange={onEmailChange} 
        required 
      />
    </div>
    <div className="form-group">
      <label>Password:</label>
      <input 
        type="password" 
        value={password} 
        onChange={onPasswordChange} 
        required 
      />
    </div>
    <button className="submit-button" type="submit">{isLogin ? 'Logga in' : 'Skapa konto'}</button>
  </form>
);

export default AuthForm;
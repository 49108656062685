import React, { useState } from 'react';
import { auth } from '../../integrations/firebaseConfig';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import AuthForm from './AuthForm';
import './AuthPage.css';

const AuthPage = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isLogin) {
        await signInWithEmailAndPassword(auth, email, password);
      } else {
        await createUserWithEmailAndPassword(auth, email, password);
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const toggleAuthMode = () => setIsLogin(!isLogin);

  return (
    <div className="auth-container">
      <h2>{isLogin ? 'Logga in' : 'Skapa Konto'}</h2>
      <AuthForm 
        isLogin={isLogin}
        email={email}
        password={password}
        onEmailChange={(e) => setEmail(e.target.value)}
        onPasswordChange={(e) => setPassword(e.target.value)}
        onSubmit={handleSubmit}
      />
      <button className="toggle-button" onClick={toggleAuthMode}>
        {isLogin ? 'Behöver du ett konto? Skapa konto!' : 'Har du ett konto? Logga in!'}
      </button>
    </div>
  );
};

export default AuthPage;
import axios from 'axios';


const API_URL = process.env.REACT_APP_API_URL || 'https://powermate-api.com';

export const executeQuery = async (query) => {
  try {
    const response = await axios.post(`${API_URL}/execute_query`, {
      query
    });
    console.log('Full response:', response);
    return response.data;
  } catch (error) {
    console.error('Error executing query:', error.response?.data || error.message);
    throw error;
  }
};
// src/components/Header.js
import React from 'react';
import styled from 'styled-components';
import logoImage from '../assets/powermate-logo.png'; // Adjust the path as needed

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const Logo = styled.img`
  height: 40px;
  margin-right: 1rem;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  color: #333;
  margin: 0;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

const WelcomeText = styled.span`
  margin-right: 1rem;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const Header = ({ user, onLogout, onChangePassword }) => {
  return (
    <StyledHeader>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Logo src={logoImage} alt="PowerMate Logo" />
        <Title>PowerMate</Title>
      </div>
      {user && (
        <UserInfo>
          <WelcomeText>Välkommen, {user.email}!</WelcomeText>
          <Button onClick={onChangePassword}>Byt Lösenord</Button>
          <Button onClick={onLogout}>Logga ut</Button>
        </UserInfo>
      )}
    </StyledHeader>
  );
};

export default Header;
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const userDatasets = {
    'rasmus.bjersing@gmail.com': 'parkIt',
    'andre.vesthed@chargeit.se': 'chargeIt',
    'vincent.holmen@chargeit.se': 'chargeIt',
    'filip.eriksson@parkit.se': 'parkIt',
    'tomas.nordstrom@powermate.tech': 'powerMate',
    'gustav.ring@powermate.tech': 'powerMate',
    'christoffer.eriksson@powermate.tech': 'powerMate',
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
import React, { useState, useMemo, useEffect } from 'react';
import { useTable } from 'react-table';
import { tableData, getColumns, columnMap } from '../../data/tableMappings.js';
import TableButtons from './TableButtons.js';
import Table from './Table.js';
import { updateDataContent } from '../../data/dataUpdater';
import { exportToExcel } from '../../utils/excelExport'; 

const ChargePointTable = ({ dataset }) => {
  const [activeTable, setActiveTable] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dataVersion, setDataVersion] = useState(0);

  const availableTables = useMemo(() => {
    console.log('Calculating available tables for dataset:', dataset);
    const tables = Object.keys(tableData[dataset] || {});
    console.log('Available tables:', tables);
    return tables;
  }, [dataset, dataVersion]);

  const data = useMemo(() => {
    console.log('Recalculating data');
    console.log('Dataset:', dataset);
    console.log('Active Table:', activeTable);
    console.log('Table Data:', tableData[dataset]?.[activeTable]);
    return tableData[dataset]?.[activeTable] || [];
  }, [dataset, activeTable, dataVersion]);

  /* const columns = useMemo(() => {
    console.log('Recalculating columns');
    return getColumns(data);
  }, [data]);
  */
  const columns = useMemo(() => {
    console.log('Recalculating columns');
    // Get columns in the exact order they are found in the data
    const dataKeys = data.length > 0 ? Object.keys(data[0]) : [];
    return dataKeys.map(key => ({
        Header: columnMap[key] || key,  // Use a mapping if available, else use the key as the header
        accessor: key
    }));
}, [data]);

  useEffect(() => {
    console.log('Effect: Fetching data');
    const fetchData = async () => {
      try {
        await updateDataContent(dataset);
        console.log('Data fetched successfully');
        console.log('Full Table Data:', JSON.stringify(tableData, null, 2));
        setDataVersion(prev => prev + 1);  // Trigger re-render
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log('Effect: Setting initial active table');
    if (availableTables.length > 0 && !activeTable) {
      console.log('Setting active table to:', availableTables[0]);
      setActiveTable(availableTables[0]);
    }
  }, [availableTables, activeTable]);

  useEffect(() => {
    console.log('State Update:');
    console.log('Dataset:', dataset);
    console.log('Active Table:', activeTable);
    console.log('Available Tables:', availableTables);
    console.log('Current Data:', data);
  }, [dataset, activeTable, availableTables, data]);

  const tableInstance = useTable({ columns, data });

  const handleExportToExcel = () => {
    exportToExcel(data, `charge_points_${activeTable}`);
  };

  console.log('Render - Dataset:', dataset);
  console.log('Render - Active Table:', activeTable);
  console.log('Render - Data:', data);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!activeTable) {
    return <div>No active table selected</div>;
  }

  if (!data || data.length === 0) {
    return <div>No data available for the selected table: {activeTable}</div>;
  }
  return (
    <div>
      <TableButtons
        setActiveTable={setActiveTable}
        exportToExcel={handleExportToExcel}
        availableTables={availableTables}
        activeTable={activeTable}
      />
      <Table tableInstance={tableInstance} />
    </div>
  );
};

export default ChargePointTable;
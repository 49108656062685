import React from 'react';
import styles from './ChargePointTable.module.css';
import { tableNameMapping } from '../../data/tableMappings.js';

const TableButtons = ({ setActiveTable, exportToExcel, availableTables, activeTable }) => (
  <div className={styles.container}>
    <select
      value={activeTable}
      onChange={(e) => setActiveTable(e.target.value)}
      className={styles.dropdown}
    >
      {availableTables.map(tableName => (
        <option key={tableName} value={tableName}>
          {tableNameMapping[tableName] || tableName}
        </option>
      ))}
    </select>
    <button onClick={exportToExcel} className={styles.button}>Exportera till Excel</button>
  </div>
);

export default TableButtons;